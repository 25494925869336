import React from 'react';
import './Alert.scss'
import {alertService, alertType} from "../../services/alertService";

type MyProp = {
    id: string,
    fade: boolean
}

class Alert extends React.Component<MyProp, any> {
    subscription: any;

    constructor(props: MyProp) {
        super(props);

        this.state = {
            alerts: []
        };
    }

    componentDidMount() {
        // subscribe to new alert notifications
        this.subscription = alertService
            .onAlert(this.props.id)
            .subscribe(alert => {
                // clear alerts when an empty alert is received
                if (!alert.message) {
                    // filter out alerts without 'keepAfterRouteChange' flag
                    const alerts = this.state.alerts.filter((x: any) => x.keepAfterRouteChange);

                    // remove 'keepAfterRouteChange' flag on the rest
                    alerts.forEach((x: any) => delete x.keepAfterRouteChange);

                    this.setState({ alerts });
                    return;
                }

                // add alert to array
                this.setState({ alerts: [...this.state.alerts, alert] });

                setTimeout(() => this.removeAlert(alert), 3000);
            });
    }

    componentWillUnmount() {
        // unsubscribe & unlisten to avoid memory leaks
        this.subscription.unsubscribe();
    }

    removeAlert(alert: any) {
        if (this.props.fade) {
            // fade out alert
            const alertWithFade = { ...alert, fade: true };
            this.setState({ alerts: this.state.alerts.map((x: any) => x === alert ? alertWithFade : x) });

            // remove alert after faded out
            setTimeout(() => {
                this.setState({ alerts: this.state.alerts.filter((x: any) => x !== alertWithFade) })
            }, 250);
        } else {
            // remove alert
            this.setState({ alerts: this.state.alerts.filter((x: any) => x !== alert) })
        }
    }

    cssClasses(alert: any) {
        if (!alert) return;

        const classes = ['alert', 'alert-dismissable', 'text-center'];

        const alertTypeClass = {
            [alertType.success]: 'alert alert-success',
            [alertType.error]: 'alert alert-danger',
            [alertType.info]: 'alert alert-info',
            [alertType.warning]: 'alert alert-warning'
        }

        classes.push(alertTypeClass[alert.type]);

        if (alert.fade) {
            classes.push('fade');
        }

        return classes.join(' ');
    }

    render() {
        const { alerts } = this.state;
        if (!alerts.length) return null;
        return (
            <div className="m-3 Alert">
                {alerts.map((alert: any, index: number) =>
                    <div key={index} className={this.cssClasses(alert)}>
                        <span dangerouslySetInnerHTML={{__html: alert.message}}/>
                    </div>
                )}
            </div>
        );
    }
}

export { Alert };
