import {FormModel} from "../models/form.model";
import axios from "axios";

export function uploadFiles(data: FormData) {
    return axios.post(`${process.env.REACT_APP_SERVER_URL}/upload-files`, data)
}

export function postForm(form: FormModel) {
    return axios.post(`${process.env.REACT_APP_SERVER_URL}/save-form`, form)
}
