import React from 'react';
import './App.scss';
import {Alert} from "./components/Alert/Alert";
import logo2x from "../assets/images/logo@2x.png";
import logo from "../assets/images/logo.png";
import kdo from "../assets/images/IMG KDO@2x.png";
import header from "../assets/images/IMG HEADER@2x.png"
import imgParole from "../assets/images/LOGO-2@2x.png"
import AppForm from "./components/AppForm/AppForm";
import {Route, Routes} from "react-router-dom";
import FormSended from "./components/FormSended/FormSended";

function App() {

    return (
      <div className={'App container px-0'}>
          <Alert id={'default-alert'} fade={false}/>
          <nav className="row background-green w-100 mx-0">
              <div className="col d-flex flex-row p-0">
                  <div className="me-auto my-0">
                      <a href="https://monsieurstore.com/" className="text-decoration-none text-white">
                          <img src={logo} alt="logo mr store" className={"logo"}/>
                          <img src={logo2x} alt="logo mr store" className={"logo2x"}/>
                      </a>
                  </div>
              </div>
          </nav>
          <div className="row background-green w-100 mx-0">
              <div className="col-5 d-flex flex-column text-center header-1 pt-3 px-0">
                  <h1 className="text-white fw-bold text-uppercase mt-auto mb-1">partagez <br/> vos photos</h1>
                  <p className="text-white text-uppercase my-2 fw-bold">
                      de pergola, store,<br/>
                      volet… installés par votre<br/>
                      monsieur store
                  </p>
                  <p className="text-yellow fw-bold mt-1 mb-4">& tentez de<br/>remporter de <br/>nombreux cadeaux !</p>
                  <hr className="m-auto"/>
                  <img src={imgParole} alt="image logo 2" className="m-auto w-50"/>
              </div>
              <div className="col d-flex px-0 header-1-image">
                  <img src={header} alt="Image header" className="ms-auto me-0 w-100"/>
              </div>
          </div>
          <div className="row background-dark-green w-100 mx-0">
              <div className="col-12 d-flex">
                  <h1 className="text-yellow text-center fw-bold m-auto py-3 title-header-2">Vous avez tout à y gagner !</h1>
              </div>
              <div className="col-6 d-flex">
                  <img src={kdo} alt="Image kdo" className="m-auto image-header-2"/>
              </div>
              <div className="col-6 d-flex flex-column text-center header-2 my-auto pt-3 px-0">
                  <p className="text-white fw-bold">Des braseros<br/>offerts <sup className={"asterisk"}>(1)</sup></p>
                  <p className="text-white">aux ambassadeurs sélectionnés par le jury Monsieur Store</p>
                  <p className="text-white fw-bold d-flex flex-column">
                      <span className={"mb-3"}>&</span>
                      <span className={"span-150 my-0"}>150 €</span>
                      <span className={"span-remise mt-0"}> (de remise automatique) <sup className={"asterisk"}>(2)</sup></span>
                  </p>
                  <p className="text-white">pour votre futur projet Monsieur Store</p>
              </div>
          </div>
          <Routes>
              <Route path={"/"} element={<AppForm />} />
              <Route path={"/form-sended"} element={<FormSended />} />
          </Routes>
          <div className={"mentions-legales row w-100 mx-0"}>
              <p>
                  <sup>(1)</sup> Visuel non contractuel.
                  Les ambassadeurs sélectionnés par le jury Monsieur Store seront contactés directement par mail après la délibération.
              </p>
              <p>
                  <sup>(2)</sup> 150 € de remise valable pour toute demande préalable de devis en ligne,
                  à valoir sur l’achat parmi les produits suivants : pergola à lames, pergola à toile enroulable, stores extérieurs, stores
                  intérieurs, volets roulants, volets battants, portail, porte de garage, fenêtres.
                  Exclusivité web, non cumulable avec d’autres promotions en cours.
                  1 participation par foyer (1 domiciliation).
                  Montant minimum d’achat : 1 500 € TTC.
                  Pose comprise.
                  Remise valable pour un devis signé jusqu’au 31/10/2022.
              </p>
          </div>
      </div>
  );
}

export default App;
