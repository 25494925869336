export class FormModel {
    filename: string[];
    nom: string | null;
    prenom: string | null;
    email: string | null;
    produit: string | null;
    // anneeInstallation: number | null;
    codePostal: number | null;
    inscriptionNewsletter: boolean
    donneesPersonnelles: boolean;
    utilisationPhoto: boolean;


    constructor() {
        this.filename = [];
        this.nom = null;
        this.prenom = null;
        this.email = null;
        this.produit = null;
        // this.anneeInstallation =  null;
        this.codePostal = null;
        this.inscriptionNewsletter = false;
        this.donneesPersonnelles = false;
        this.utilisationPhoto = false;
    }
}
