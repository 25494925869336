import React from "react";
import './FormSended.scss'
import {Link} from "react-router-dom";
import {faLongArrowAltRight} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


function FormSended() {
    return (
        <div className="row w-100 mx-0" id={"FormSended"}>
            <div className="col d-flex flex-column">
                <p className={"text-green text-center mt-3"}>
                    Merci d'avoir participé ! 🙂<br/>
                    Vous serez recontacté par mail pour la suite du programme ambassadeurs, si vos photos sont sélectionnées, ou pour vous faire parvenir votre remise de 150 € à valoir sur un futur achat.
                </p>
                <a href="https://monsieurstore.com" className={"btn btn-danger btn-remise text-white text-center mx-auto mb-5"}>
                    retour au site Monsieur Store
                </a>
            </div>
        </div>
    )
}

export default FormSended;
