import React, {useEffect, useState} from "react";
import './AppForm.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCaretDown, faCaretUp, faTimes} from '@fortawesome/free-solid-svg-icons'
import {FormModel} from "../../models/form.model";
import {postForm, uploadFiles} from "../../services/httpService";
import {alertService} from "../../services/alertService";
import ReCAPTCHA from "react-google-recaptcha";
import {sliderItems} from "../../models/slider-item.model";
import {useNavigate} from "react-router-dom";
import imgDropbox from "../../../assets/images/big-MicrosoftTeams-image.png"

function AppForm() {

    const [form, setForm] = useState(new FormModel());
    const [files, setFiles] = useState({file: [] as any[], imagePreviewUrl: [] as string[]});
    const [showSlider, setShowSlider] = useState(false);
    const [captchaValidated, setCaptchaValidated] = useState(false);
    const [submit, setSubmit] = useState(false);
    const [upload, setUpload] = useState(false);

    const navigate = useNavigate()

    const maxFileSize: number = process.env.REACT_APP_MAX_FILE_SIZE ? Number(process.env.REACT_APP_MAX_FILE_SIZE) : 1024;

    useEffect(() => {
        if (submit) {
            postForm(form).then(() => {
                alertService.success('Le formulaire est enregistré', {autoClose: true, keepAfterRouteChange: false});
                navigate("/form-sended#FormSended");
            }).catch((e) => {
                console.log(e.response.data, e.response.data.error);
                alertService.error('Une erreur est survenue : ' + e.response.data.error, {autoClose: true, keepAfterRouteChange: false})
            });
            setSubmit(false);
            setUpload(false);
        }
    }, [form, submit, navigate]);

    const handleInputChange = (event: any) => {
        const value = event.target.type ==='checkbox' ? event.target.checked : event.target.value;
        const name = event.target.name;
        setForm({
            ...form,
            [name]: value
        } as FormModel);
    }

    const handleImageChange = (event: any) => {
        event.preventDefault();
        let newFiles: any[] = []
        let newFilesUrl: any[] = [];

        // Pour chaque fichier uploadé,
        // on verifie que ce soit bien une image
        // on demande d'url objet pour pouvoir preview
        for (const file of event.target.files) {
            console.log(file, maxFileSize);
            if (file.size > maxFileSize) {
                alertService.error('Image trop lourde. Veuillez en choisir une autre', {autoClose: true, keepAfterRouteChange: false})
            }else if (!file.type.match(/image\/.*/)) {
                alertService.error('Seule les images sont autorisées', {autoClose: true, keepAfterRouteChange: false})
            } else {
                newFiles.push(file)
                newFilesUrl.push(URL.createObjectURL(file));
                alertService.success('Image téléchargée', {autoClose: true, keepAfterRouteChange: false})
            }
        }

        setFiles({
            file: [...files.file, ...newFiles],
            imagePreviewUrl: [...files.imagePreviewUrl, ...newFilesUrl]
        });
    }

    const handleImageClick = (event: any) => {
        event.preventDefault();

        let target = event.target;

        // Si on clique sur le svg (ou le path),
        // il faut donc remonter les element pour tombe sur le lien avec l'index en id
        while (target.localName !== 'button') {
            target = target.parentElement;
        }

        const id: number = Number.parseInt(target.id);

        let newFile = files.file;
        newFile.splice(id, 1);
        let newFileUrl = files.imagePreviewUrl;
        newFileUrl.splice(id, 1)

        setFiles({
            file: newFile,
            imagePreviewUrl: newFileUrl
        });

    }

    const previewImage = () => {
      return files.imagePreviewUrl.map((value, index) =>
          <div className="container-image col-4 my-2 mx-auto">
              <img id={index.toString()} src={value} alt={""} className={'true-image'} />
              <div className="overlay">
                  <button type="button" className="icon" id={index.toString()} title="Supprimer le fichier" onClick={handleImageClick}>
                      <FontAwesomeIcon icon={faTimes}/>
                  </button>
              </div>
          </div>
      )
    }

    /**
     * On affiche au minimum 3 placeholders
     */
    const previewPlaceholder = () => {
        const placeholders: number[] = []

        for (let i = files.imagePreviewUrl.length; i < 3; i++) {
            placeholders.push(i)
        }

        return placeholders.map( key =>
            <div className="container-image col-4 my-2 mx-auto">
                <img id={key.toString()} src="https://via.placeholder.com/150/f7f7f7/f7f7f7?text=image+placeholder" alt={""} className={""} />
            </div>
        )
    }

    const handleSliderDropDown = (_ : any) => {
        setShowSlider(!showSlider)
    }

    const handleChangeCaptcha = (event: any) => {
        console.log("captcha value : ", event)
        if (event) {
            setCaptchaValidated(true);
        }
    }

    const handleSubmit = (event: any) => {
        event.preventDefault();

        // Form Validation
        let error: string | null = null;

        if (form.nom === null || form.prenom === null || form.email === null /*|| form.produit === null*/
            /*|| form.anneeInstallation === null*/ || form.codePostal === null
            || !form.donneesPersonnelles || !form.utilisationPhoto) {
            error = 'Veuillez remplir tous les champs obligatoires.'
        }else if (form.nom.trim() === '' || form.prenom.trim() === '' || form.email.trim() === ''
            /*|| form.produit.trim() === ''*/) {
            error = 'Veuillez remplir tous les champs obligatoires.'
        }else if (!form.codePostal.toString().match(/^[0-9]{5}$/)) {
            error = 'Le code postal doit être composé de 5 chiffres'
        }else if (!form.email.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/)) {
            error = 'Veuillez saisir une adresse mail valide'
        }else if (files.file.length === 0) {
            error = 'Veuillez ajouter au moins une photo.'
        } else if (!captchaValidated) {
            error = 'Veuillez valider le captcha'
        } else if (submit || upload) {
            error = 'Votre demande est déjà en cours de traitement.'
        }

        // Send data to server
        if (error === null) {
            setUpload(true);
            const data = new FormData();
            files.file.forEach((value => data.append('file', value)));
            uploadFiles(data).then(res =>{
                setForm({
                    ...form,
                    filename: res.data.filename
                } as FormModel);
                setSubmit(true);
            }).catch((e) => {
                setUpload(false);
                alertService.error(
                    'L\'enregistrement des photos n\'a pas abouti',
                    {autoClose: true, keepAfterRouteChange: false}
                );
            });
        } else {
            alertService.error(error, {autoClose: true, keepAfterRouteChange: false});
        }
    }

    const displayFileMaxSize = () => {
        if(maxFileSize < 1_024) {
            return maxFileSize + ' octets';
        } else if(maxFileSize >= 1_024 && maxFileSize < 1_048_576) {
            return (maxFileSize/1_024).toFixed(1) + ' Ko';
        } else if(maxFileSize >= 1_048_576) {
            return (maxFileSize/1_048_576).toFixed(1) + ' Mo';
        }
    }

    const displayLoader = () => {
        if (upload) {
            return (
                <div className="loader__overlay">
                    <div className="overlay__inner">
                        <div className="overlay__content"><span className="spinner" /></div>
                    </div>
                </div>
            );
        }
    }

    return (
        <form className="AppForm mx-0 w-100" onSubmit={handleSubmit} noValidate={true}>
            <div className="Etape py-2"> Étape 1/2</div>
            <div className="Etape-arrow" />
            <div className="Form-images pt-5 pb-3">
                <div className="dropzone mx-3 d-flex flex-column">
                    <img src={imgDropbox} alt="image pour dropzone" className={"border-0 mx-auto mt-4 mb-2"}/>
                    <p className="fw-bold my-auto mx-4">Glissez/Déposez vos photos pergola,<br/>store, volet… Monsieur Store ici</p>
                    <p className="mx-4">Taille maximale autorisée : {displayFileMaxSize()}</p>
                    <button className="btn btn-warning m-auto text-uppercase py-2">Je télécharge mes photos</button>
                    <input type="file" accept={'image/*'} className="m-auto" multiple={true} onChange={handleImageChange}/>
                </div>
                <div className="row d-flex flex-row my-4 px-3 mx-0">
                    {previewImage()}
                    {previewPlaceholder()}
                </div>
                <div className="conseils row d-flex flex-column background-green mx-3 py-3">
                    <div className="col-12 d-flex">
                        <h4 className="text-center text-yellow fw-bold m-auto conseil-title">Nos conseils pour faire<br/>de belles photos</h4>
                        <div className={"me-2 text-white"} onClick={handleSliderDropDown}>
                            <FontAwesomeIcon icon={showSlider ? faCaretUp : faCaretDown} size={"2x"}/>
                        </div>
                    </div>
                    <div id="carouselExampleControls" className={"mt-3 px-0 carousel slide " + (showSlider ? " d-flex" : "d-none")} data-bs-ride="carousel">
                        <button className="carousel-control-prev" type="button"
                                data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true" />
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <div className="carousel-inner">
                            {
                                sliderItems.map((item, index) => (
                                    <div className={"carousel-item" + (index === 0 ? " active" : "")}>
                                        <div className="row">
                                            <div className="col-5 d-flex">
                                                <img src={item.icon} alt="" className={"w-75 m-auto"}/>
                                            </div>
                                            <div className="col-7 d-flex">
                                                <p className="text-white">
                                                    {item.text}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                        <button className="carousel-control-next" type="button"
                                data-bs-target="#carouselExampleControls" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true" />
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
            </div>
            <div className="Etape py-2"> Étape 2/2</div>
            <div className="Etape-arrow" />
            <div className="Form-input p-3">
                <div className="row">
                    <div className="col-12 d-flex">
                        <p className="ms-auto text-green label-required">
                            <span className="required">*</span> : champ obligatoire
                        </p>
                    </div>
                    <div className="col mb-3">
                        <label htmlFor="nom" className="form-label">Nom<span className={'required'}>*</span></label>
                        <input type="text" className="form-control" id="nom" name={'nom'} placeholder="" required={true}
                               onChange={handleInputChange} />
                    </div>
                    <div className="col mb-3">
                        <label htmlFor="prenom" className="form-label">Prénom<span className={'required'}>*</span></label>
                        <input type="text" className="form-control" id="prenom" name={'prenom'} placeholder="" required={true}
                               onChange={handleInputChange} />
                    </div>
                    <div className="col-12 mb-3">
                        <label htmlFor="email" className="form-label">Email<span className={'required'}>*</span></label>
                        <input type="email" className="form-control" id="email" name={'email'} placeholder="" required={true}
                               onChange={handleInputChange} />
                    </div>
                    <div className="col-12 mb-3">
                        <label htmlFor="produit" className="form-label">Produit(s) installé(s) par Monsieur Store</label>
                        <input type="text" className="form-control" id="produit" name={'produit'} placeholder="" required={true}
                               onChange={handleInputChange} />
                    </div>
                    {/*<div className="col">*/}
                    {/*    <label htmlFor="anneeInstallation" className="form-label">Année d'installation<span className={'required'}>*</span></label>*/}
                    {/*    <input type="number" className="form-control" id="anneeInstallation" name={'anneeInstallation'}*/}
                    {/*           placeholder="" pattern={"[0-9]{4}"} required={true}*/}
                    {/*           onChange={handleInputChange} />*/}
                    {/*</div>*/}
                    <div className="col">
                        <label htmlFor="codePostal" className="form-label">Code Postal<span className={'required'}>*</span></label>
                        <input type="text" className="form-control" id="codePostal" name={'codePostal'} placeholder="" required={true}
                               pattern="[0-9]{5}"
                               onChange={handleInputChange} />
                    </div>
                    <div className="col-12 row mt-4 mb-1">
                        <div className="col-3 d-flex">
                            <input className="form-check-input mx-auto" type="checkbox" value="" id="inscriptionNewsletter" name={'inscriptionNewsletter'}
                                   required={false} onChange={handleInputChange} />
                            <span className="required opacity-0">*</span>
                        </div>
                        <div className="col d-flex">
                            <label className="form-check-label my-auto" htmlFor="inscriptionNewsletter">
                                Je souhaite m’inscrire aux emailings et newsletters pour profiter de tous les conseils et offres de Monsieur Store.
                            </label>
                        </div>
                    </div>
                    <div className="col-12 row mt-2 mb-1">
                        <div className="col-3 d-flex">
                            <input className="form-check-input mx-auto" type="checkbox" value="" id="donneesPersonnelles" name={'donneesPersonnelles'}
                                   required={true} onChange={handleInputChange} />
                            <span className="required">*</span>
                        </div>
                        <div className="col d-flex">
                            <label className="form-check-label my-auto" htmlFor="donneesPersonnelles">
                                En cochant cette case j’autorise Monsieur Store à traiter et conserver les données recueillies pour les utiliser dans le
                                cadre du programme ambassadeurs. Pour en savoir plus sur la gestion de vos données personnelles et pour exercer vos droits,
                                reportez-vous à notre « Politique de confidentialité ».
                            </label>
                        </div>
                    </div>
                    <div className="col-12 row mt-2 mb-4">
                        <div className="col-3 d-flex">
                            <input className="form-check-input mx-auto" type="checkbox" value="" id="utilisationPhoto" name={'utilisationPhoto'}
                                   onChange={handleInputChange} />
                            <span className="required">*</span>
                        </div>
                        <div className="col d-flex">
                            <label className="form-check-label my-auto" htmlFor="utilisationPhoto">
                                En cochant cette case, j’autorise Monsieur Store à utiliser mes photos de réalisations, pour une durée de 5 ans,
                                en France, sur tous les supports de communication matériels et dans le monde pour les supports de communication
                                immatériels.
                            </label>
                        </div>
                    </div>
                    <div className="col-12">
                        <p className="disclaimer">
                            La société Monsieur Store, responsable du traitement, met en œuvre un traitement ayant pour
                            finalité de fournir un service auprès de ses clients et prospects (devenir ambassadeur, s’inscrire à
                            la Newsletter, etc.). Les champs signalés par un astérisque correspondent à des données à remplir
                            obligatoirement pour recevoir le service de la part de Monsieur Store. Les autres champs, facultatifs,
                            correspondent à des données destinées à mieux vous connaître. Monsieur Store est également susceptible
                            d’utiliser les données de géolocalisation et navigation collectées sur son site internet. Vous pouvez
                            demander l’accès, une information complémentaire, la rectification, l’effacement, la limitation ou la
                            portabilité de vos données traitées par Monsieur Store, dans les conditions prévues par la réglementation,
                            à l’adresse suivante : <a href="mailto:infos-rgpd@monsieur-store.net">infos-rgpd@monsieur-store.net</a>.
                            Vous pouvez vous opposer au traitement de vos données à des fins commerciales, dans les conditions
                            prévues par la réglementation, à la même adresse. Pour en savoir plus, nous vous invitons à consulter
                            notre <a href="https://monsieurstore.com/politique-de-confidentialite/" className="text-decoration-underline">Politique de confidentialité</a>.
                        </p>
                    </div>
                    <div className="col-12 d-flex flex-column">
                        <div className="mx-auto pb-4">
                            <ReCAPTCHA
                                sitekey="6LeR_fEdAAAAAM07tzuqbv13cgAiNwa24uMI-Y-E"
                                onChange={handleChangeCaptcha}
                            />
                        </div>
                        <button type={'submit'} className="btn btn-warning btn-submit m-auto mb-5 text-white text-uppercase py-2 px-3">C'est parti !</button>
                    </div>
                </div>
            </div>
            {displayLoader()}
        </form>
    )
}

export default AppForm;
