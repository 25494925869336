import noUser from "../../assets/images/no-user.svg"
import sun from "../../assets/images/sun.svg"
import landscape from "../../assets/images/landscape.svg"
import time2 from "../../assets/images/time-2.svg"

export class SliderItem {
    public icon: any;
    public text: string;


    constructor(icon: any, text: string) {
        this.icon = icon;
        this.text = text;
    }
}

export const sliderItems: SliderItem[] = [
    {
        icon: sun,
        text: 'Privilégiez la période de journée la plus lumineuse et si possible, évitez les contre-jours'
    },
    {
        icon: landscape,
        text: 'Orientez votre smartphone au format paysage et prenez un peu de recul'
    },
    {
        icon: time2,
        text: 'Si votre produit se déploie (store, portail, volets...) pensez à faire une photo ouverte et l\'autre fermée'
    },
    {
        icon: noUser,
        text: 'Evitez d\'apparaître sur les photos, cette fois-ci les stars ce sont le produit et votre maison ;)'
            //+ String.fromCodePoint(0x1F609)
    }
];

